<template>
    <div>
        <!-- 添加一个 <el-button>，点击时调用 addInput 方法 -->
        <el-button @click="addInput" style="font-size: 20px;margin: 0px 0px 0px 15px;">+</el-button>
        <el-row v-for="(input, index) in inputs"
                :key="index"
                :span="24"
                class="addInputStyle">
            <!-- 使用 v-for 指令遍历 inputs 数组并渲染多个 <el-col> 元素 -->
            <el-col :span="3" class="selectTitle"><span>資產服務等級<span class="red">*</span>:</span></el-col>

            <el-col :span="4">
                <el-input
                        placeholder="請輸入資產服務等級"
                        name="serviceLevelType"
                        v-model="input.serviceLevel"
                        @input="emitInputs"
                ></el-input>
            </el-col>
            <el-col :span="3" class="selectTitle"><span>折扣率:</span></el-col>
            <el-col :span="4">
                <el-input
                        placeholder="請輸入折扣率"
                        name="discountPercentageType"
                        v-model="input.discountPercentage"
                        @input="emitInputs"
                        type="number"
                ></el-input>
            </el-col>
            <el-col :span="3" class="selectTitle"><span>折扣金額:</span></el-col>
            <el-col :span="4">
                <el-input
                        placeholder="請輸入折扣金額"
                        name="discountAmountType"
                        v-model="input.discountAmount"
                        @input="emitInputs"
                        type="number"
                ></el-input>
            </el-col>
            <el-col :span="3" class="selectTitle">
                <span>是否有贈送禮品:</span>
            </el-col>
            <el-col :span="4">
                <el-radio-group v-model="input.isGift">
                    <el-radio label='1'>有</el-radio>
                    <el-radio label='0'>沒有</el-radio>
                </el-radio-group>
            </el-col>
            <!-- 添加一个删除按钮 -->
            <el-button @click="removeInput(index, input.serviceLevelTypeId)" style="font-size: 20px;">-
            </el-button>

        </el-row>
    </div>
</template>

<script>
    export default {
        name: "editServiceLevelType",
        props: {
            initialInputs: {
                type: Array,
                default: () => []
            },
            initCompanyId: null,
        },
        data() {
            return {
                inputs: [] // 用于存储所有输入框状态的数组
            };
        },
        created() {
            // 在组件创建时，将 props 中的数据复制到本地的 inputs 中
            //this.inputs = [...this.initialInputs];
            this.inputs = [...this.initialInputs].map(item => {
                if (typeof item.isGift === 'boolean') {
                    return {
                        ...item,
                        isGift: item.isGift ? '1' : '0'
                    };
                }
                return item;
            });
            this.emitInputs();
        },
        methods: {
            addInput() {
                console.log("initCompanyId=" + this.initCompanyId);
                // 添加一个新的输入框状态到数组中，每个输入框状态包含一个 value 字段
                this.inputs.push({
                    companyId: this.initCompanyId,
                    serviceLevel: '',
                    discountPercentage: null,
                    discountAmount: null,
                    isGift: null,
                    serviceLevelTypeId: null,
                });
                this.emitInputs();
            },
            removeInput(removeIndex, serviceLevelTypeId) {
                console.log("刪除輸入框 removeIndex=" + removeIndex + "，serviceLevelTypeId=" + serviceLevelTypeId);
                // 如果 assetsModelTypeId 不为空，则不执行删除操作
                if (serviceLevelTypeId !== null && serviceLevelTypeId !== undefined) {
                    // 可以添加提示或其他处理逻辑
                    this.$api.openMsg("禁止刪除原有的資產服務等級資料!", "error");
                    return;
                }
                // 从数组中移除
                this.inputs.splice(removeIndex, 1);
            },
            emitInputs() {
                console.log("editServiceLevelType this.inputs=" + JSON.stringify(this.inputs));
                // 触发自定义事件，并传递 inputs 数据
                this.$emit('updateServiceLevelInputs', this.inputs);
            }
        }
    };
</script>

<style scoped>
    .addInputStyle {
        text-align: left;
        margin: 20px 0px 15px 15px;
        /*margin-bottom: 10px; !* 可选的样式，用于分隔输入框 *!*/
        display: flex; /* 使输入框和按钮在同一行 */
        align-items: center;
    }

    .addInputStyle .el-input {
        flex: 1; /* 输入框占满剩余空间 */
    }
</style>