<template>
    <div class="popDialog">
        <el-dialog
                v-if="editDialog"
                width="95%"
                top="30px"
                :visible.sync="editDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog">
            <template v-slot:title>
                <el-row>
                    <el-col :span="12" class="selectTitleMax">
                        <span>編輯公司資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>公司名稱<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="companyName" placeholder="請輸入公司名稱"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>公司地址:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="address" placeholder="請輸入公司地址"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isActive">
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>產業類型<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="companyType" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyTypeOptions"
                                            :key="item.companyType"
                                            :label="item.companyTypeName"
                                            :value="item.companyType"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitleMid">
                                <span>資產類型資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <EditAssetsModelType :initCompanyId=companyId :initialInputs="assetsModelOuputs"
                                                 @update-inputs="handleUpdateAssetsModelInputs"></EditAssetsModelType>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitleMid">
                                <span>資產服務等級資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <EditServiceLevelType :initCompanyId=companyId :initialInputs="serviceLevelOuputs"
                                                  @updateServiceLevelInputs="handleUpdateServiceLevelInputs"></EditServiceLevelType>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="editBranch">編輯分店</el-button>
                <el-button @click="editTemplate">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import EditAssetsModelType from '@/views/company/EditAssetsModelType.vue'
    import EditServiceLevelType from '@/views/company/EditServiceLevelType.vue'

    export default {
        name: "editCompanyDialog",
        components: {
            EditAssetsModelType,
            EditServiceLevelType
        },
        props: {
            editDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                row: null,
                companyName: "",
                address: "",
                token: null,
                companyId: null,
                isActive: null,
                role: null,
                reload: false,
                companyType: null,
                companyTypeOptions: [],
                assetsModelInputs: [], // 用于存储从子组件传递过来的输入框数据
                serviceLevelInputs: [], // 用于存储从子组件传递过来的输入框数据
                assetsModelOuputs: [], // 用于存储从子组件传递过来的输入框数据
                serviceLevelOuputs: [], // 用于存储从子组件传递过来的输入框数据
            }
        },
        mounted() {
        },
        methods: {
            init(row) {
                this.row = row;
                this.companyId = row.companyId;
                this.companyName = row.companyName;
                this.address = row.address;
                this.isActive = row.isActive == true ? "1" : "0";
                this.companyType = row.companyType;
                this.assetsModelOuputs = row.assetsModelList;
                this.serviceLevelOuputs = row.serviceLevelList;
                console.log("companyName=" + row.companyName);
                console.log("address=" + row.address);
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    this.role = this.loginReturnDto.role;
                    //console.log("editCompanyDialog取得token=" + this.token);
                }
                this.getCompanyTypeList();
            },
            closeDialog() {
                console.log("關閉Pop");
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
            },
            async editTemplate() {
                //更新
                //開啟特效
                let tmp = this;
                tmp.loadingView();
                this.serviceLevelInputs = this.serviceLevelInputs.map(item => {
                    if (typeof item.isGift === 'string') {
                        return {
                            ...item,
                            isGift: item.isGift == null || item.isGift == '0' ? false : true
                        };
                    } else if (typeof item.isGift === 'boolean') {
                        return {
                            ...item,
                            isGift: item.isGift
                        };
                    } else {
                        return {
                            ...item,
                            isGift: false
                        };
                    }
                });
                let url = "company/updated/company";
                let parameter = {
                    "companyId": this.companyId,
                    "companyName": this.companyName,
                    "address": this.address,
                    "isActive": this.isActive == null ? null : this.isActive == "0" ? false : true,
                    "companyType": this.companyType,
                    "assetsModelDtoList": this.assetsModelInputs,
                    "serviceLevelDtoList": this.serviceLevelInputs,
                };
                try {
                    console.log("editCompanyDialog parameter=" + JSON.stringify(parameter));
                    if (!this.checkAddParameter(parameter)) {
                        console.log("更新驗證資料失敗!");
                        return;
                    }
                    console.log("更新資料驗證完畢，開始拋送!");
                    tmp.reload = true;
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                    if (responseData) {
                        tmp.$api.openMsg("更新資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog();
                            // tmp.$emit('init');
                        }, 1000);
                    } else {
                        tmp.$api.openMsg("更新資料失敗!", "error");
                        console.log("更新資料失敗!");
                    }
                    // eslint-disable-next-line no-empty
                } catch (error) {
                    console.log("更新資料發生異常! error=" + error);
                } finally {
                    tmp.closeLoading();
                }
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
            editBranch() {
                //跳轉到汽車列表頁
                this.$router.push({name: '分店列表', params: {companyId: this.row.companyId}});
            },
            checkAddParameter(parameter) {
                var ststus = true;
                try {
                    if (parameter != null && parameter != '') {
                        if (!parameter.companyName) {
                            console.log("公司名稱不可為空!");
                            this.$api.openMsg("公司名稱不可為空!", "error");
                            return false;
                        }
                        if (!parameter.isActive) {
                            console.log("公司是否啟用不可為空!");
                            this.$api.openMsg("公司是否啟用不可為空!", "error");
                            return false;
                        }
                        if (!parameter.companyType) {
                            console.log("產業類型不可為空!");
                            this.$api.openMsg("產業類型不可為空!", "error");
                            return false;
                        }
                        if (parameter.assetsModelDtoList && parameter.assetsModelDtoList.length > 0) {
                            console.log("資產類型資料不為空，開始判斷");
                            const isEmpty = parameter.assetsModelDtoList.some(item =>
                                !item || !item.assetsModel
                            );
                            if (isEmpty) {
                                console.log("資產類型資料不可為空!");
                                this.$api.openMsg("資產類型資料不可為空!", "error");
                                return false;
                            }
                        } else {
                            this.$api.openMsg("資產類型資料不可為空!", "error");
                            return false;
                        }
                        if (parameter.serviceLevelDtoList && parameter.serviceLevelDtoList.length > 0) {
                            const isEmpty = parameter.serviceLevelDtoList.some(item =>
                                !item || !item.serviceLevel || !item.companyId
                            );
                            // const isEmpty2 = parameter.serviceLevelDtoList.some(item =>
                            //     !item || (!item.discountPercentage && !item.discountAmount)
                            // );
                            if (isEmpty) {
                                console.log("資產服務等級資料不可為空!");
                                this.$api.openMsg("資產服務等級資料不可為空!", "error");
                                return false;
                            }
                            // if (isEmpty2) {
                            //     console.log("折扣率及折扣金額不可都為空!");
                            //     this.$api.openMsg("折扣率及折扣金額不可都為空!", "error");
                            //     return false;
                            // }
                        } else {
                            console.log("serviceLevelDtoList 不存在或为空");
                            this.$api.openMsg("資產服務等級資料不可為空!", "error");
                            return false;
                        }
                    } else {
                        console.log("必填資料不可為空!");
                        this.$api.openMsg("必填資料不可為空!", "error");
                        return false;
                    }
                } catch (e) {
                    console.log("更新驗證資料發生異常! e=" + e);
                    this.$api.openMsg("更新驗證資料發生異常!", "error");
                    return false;
                }
                return ststus;
            },
            async getCompanyTypeList() {
                //依據條件查詢全部資產服務等級資料
                let tmp = this;
                let url = "company/companyTypeList";
                //取得列表資料
                let responseData = await tmp.$api.doGetByToken(url, this.token);
                if (responseData != null) {
                    tmp.companyTypeOptions = responseData;
                    console.log("取得下拉選單-公司產業類型資料 companyTypeOptions=" + tmp.companyTypeOptions);
                }
            },
            handleUpdateAssetsModelInputs(newInputs) {
                // 更新子件傳過來的 inputs 数据
                this.assetsModelInputs = newInputs;
                console.log("this.assetsModelInputs=" + this.assetsModelInputs);
            },
            handleUpdateServiceLevelInputs(newInputs) {
                // 更新子件傳過來的 inputs 数据
                this.serviceLevelInputs = newInputs;
                console.log("this.serviceLevelInputs=" + this.serviceLevelInputs);
            }
        }
    }
</script>

<style scoped>

</style>
