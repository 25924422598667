export default [
    {
        prop: 'companyName',
        label: '公司名稱',
        width: '', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'address',
        label: '公司地址',
        width: '',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'isActiveString',
        label: '是否啟用',
        width: '',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'companyTypeString',
        label: '產業類型',
        width: '',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'action',
        label: '操作',
        width: '250',
        showOverflowTooltip: true,
        align: 'center',
        render: 'action',
        sortable: false
    }
]
